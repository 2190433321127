$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #1d1d1d;
$foreground-color: #242424;
$input-background: #232223;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: #767e8d;
$theme-color-2: #4d5a5f;
$theme-color-3: #444341;
$theme-color-4: #60646b;
$theme-color-5: #52595f;
$theme-color-6: #5a5953;

$primary-color: #8f8f8f;
$secondary-color: #707070;
$muted-color: #696969;

$gradient-color-1 : #787c85;
$gradient-color-2 : #666b75;
$gradient-color-3 : #6d727a;

$lp-bg-color-1 : #48494b;
$lp-bg-color-2 : #999da0;
$lp-bg-color-3 : #60646b;
$lp-bg-color-4 : #bebdb8;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 2 6 10 14 19;
$shadowOpacitiesTop: 0.1 0.3 0.6 0.7 0.8;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.1 0.3 0.6 0.7 0.8;

$logoPath: "../../../assets/img/eTrappLogoDesignFINAL.svg";
$logoPathMobile: "../../../assets/img/eTrappLogoDesignFINAL.svg";

$lpLogoPath: "../../../assets/img/eTrappLogoDesignFINAL.svg";
$lpLogoPathPinned: "../../../assets/img/eTrappLogoDesignFINAL.svg";


@import "../_mixins.scss";
@import "../_vien.style.scss";
